import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './ContactList.module.scss'

function ContactList() {
  return (
    <StaticQuery
      query={contactInfo}
      render={data => {
        return (
          <>
            <div className="footer__contacts">
              <div className="row align-items-center">
                {data.allContentContact.nodes.map(contact => (
                  <div
                    className="col-12 col-md"
                    key={Math.random()}
                  >
                    <div className={styles.contactList__item}>
                      <a
                        className={styles.contactList__item_link}
                        href={`${contact.name === 'email' ? 'mailto:' : contact.name === 'phoneNumber' ? 'tel:' : ''}${contact.url}`}
                        rel="noopener noreferrer"
                        target='_blank'
                      >
                        <span className={styles.contactList__item_icon}>
                          <img style={contact.name === 'location' ? { width: 14 } : {}} src={contact.icon} alt={`${contact.name} icon`} />
                        </span>
                          {`${contact.name === 'phoneNumber' ? '+' : ''}${contact.title}`}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default ContactList

const contactInfo = graphql`
  query contactInfoQuery {
    allContentContact {
      nodes {
        name
        title
        url
        icon
      }
    }
  }
`
