import React from 'react'
import ContactList from '../contactList/ContactList'
import DelayedChat from '../delayedChat/DelayedChat'

const Footer = () => (  
  <footer className="footer">
    <div className="container">
      <ContactList /> 
      <div className="footer__text">
        <div className="row">
          <div className="col-12">
            <p><a href="http://www.pozitiff.cz/" rel="noopener noreferrer" target='_blank'>designed by PozitiFF.</a></p>
          </div>
        </div>
      </div>
    </div>
    <DelayedChat />
  </footer>
)

export default Footer
