import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import ReactDelayRender from 'react-delay-render'

const DelayedChat = () => (
  <MessengerCustomerChat
    pageId="1492921591011622"
    themeColor="#F8B144"
    shouldShowDialog={false}
    loggedInGreeting="Hi! How can we help you?"
    loggedOutGreeting="Hi! How can we help you?"
    //greetingDialogDisplay="hide"
    //greetingDialogDelay="20"
  />
)

export default ReactDelayRender({ delay: 3000 })(DelayedChat)
