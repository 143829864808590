import React from 'react'
import Layout from '../components/layout'
import FeaturesList from '../components/featuresList/FeaturesList'
import Schedule from '../components/schedule/Schedule'
import Footer from '../components/footer/Footer'
import MainLogo from '../images/main-logo.svg'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="RestBar13" keywords={[`bar`, `lviv`, `restbar13`, `party`, `weekend`]} />
    <div className="siteLogo">
      <img src={MainLogo} alt="main-logo" />
    </div>
    <div className="featuresList">
      <div className="row justify-content-center align-items-center">
        <FeaturesList />
      </div>
    </div>
    <div className="schedule">
      <div className="row justify-content-center">
        <Schedule />
      </div>
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
