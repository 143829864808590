import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './FeaturesList.module.scss'

const FeaturesList = () => (
  <StaticQuery
    query={graphql`
      query Features {
        allContentFeatures {
          nodes {
            name
            title__en_
            title__ua_
            icon
            url
          }
        }
      }
    `}
    render={data => (
      data.allContentFeatures.nodes.map((feature, index) =>
        {
          return (
            (!feature.url ?
              (<div className="col-6 col-md-4 col-lg" key={index}>
                <div className={styles.featuresList__item}>
                  <img className={styles.featuresList__item_img} src={feature.icon} alt="" />
                  <p className={styles.featuresList__item_text}>{feature.title__ua_}</p>
                  <p className={styles.featuresList__item_text}>{feature.title__en_}</p>
                </div>
              </div>)
              :
              (<div className="col-6 col-md-4 col-lg" key={index}>
                <a href={feature.url} className={styles.featuresList__link} rel="noopener noreferrer" target='_blank'>
                  <div className={styles.featuresList__item}>
                    <img className={styles.featuresList__item_img} src={feature.icon} alt="" />
                    <p className={styles.featuresList__item_text}>{feature.title__ua_}</p>
                    <p className={styles.featuresList__item_text}>{feature.title__en_}</p>
                  </div>
                </a>
              </div>)
            )
          );
        }
      )
    )}
  />
)

export default FeaturesList
