import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styles from './Schedule.module.scss'

const Schedule = () => (
  <StaticQuery
    query={graphql`
      query TimeSchedule {
        allContentSchedule {
          nodes {
            name
            title
            value
            url
          }
        }
      }
    `}
    render={data => (
      data.allContentSchedule.nodes.map((item, index) =>
        {
          return (
            (!item.url ?
              (<div className="col-md-12 col-lg-6" key={index}>
                <div className={styles.schedule__item}>
                  <h2
                    className={styles.schedule__item_title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <p
                    className={styles.schedule__item_text}
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  />
                </div>
              </div>)
              :
              (<div className="col-md-12 col-lg-6" key={index}>
                <a href={item.url} className={styles.schedule__link} rel="noopener noreferrer" target='_blank'>
                  <div className={styles.schedule__item}>
                    <h2
                      className={styles.schedule__item_title}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <p
                      className={styles.schedule__item_text}
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  </div>
                </a>
              </div>)
            )
          );
        }
      )
    )}
  />
)

export default Schedule
